import React, { useEffect } from 'react';

const useBodyClass = (classNames: string | string[]): void => {
  const classNameRef = React.useRef<string[] | null>(null);
  classNameRef.current =
    typeof classNames === 'string' ? [classNames] : classNames;

  useEffect(() => {
    if (typeof document !== 'undefined' && classNameRef.current) {
      document.body.classList.add(...classNameRef.current);

      return () => {
        if (classNameRef.current) {
          document.body.classList.remove(...classNameRef.current);
        }
      };
    }
  }, [classNameRef]);
};

export default useBodyClass;
