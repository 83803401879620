import clsx from 'clsx';
import { CSSProperties, useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import styles from './BackToTopButton.module.scss';
import { BackToTopButtonProps } from './types';

const withPxUnitIfNumber = (value: CSSProperties['bottom']) => {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
};

const BackToTopButton = ({
  footerOffset,
  isFixed = false,
  thresholdBeforeShown = 250,
}: BackToTopButtonProps) => {
  const [show, setShow] = useState(false);

  const style =
    typeof footerOffset === 'undefined'
      ? undefined
      : ({
          '--footer-offset': withPxUnitIfNumber(footerOffset),
        } as CSSProperties);

  useEffect(() => {
    if (isFixed) {
      return;
    }

    const handleScroll = () =>
      setShow(() => window.scrollY > (thresholdBeforeShown || -1));

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true });
      handleScroll();
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isFixed, thresholdBeforeShown]);

  return (
    <div className={clsx(styles.base, isFixed && styles.isFixed)} style={style}>
      <div className={styles.host}>
        <button
          className={clsx(styles.button, show && styles.isShown)}
          id="back-to-top"
          onClick={() => window.scrollTo({ behavior: 'smooth', top: 0 })}
          title="Zurück nach oben"
        >
          <Icon variant="action/arrow-up" size="iconSize20" />
        </button>
      </div>
    </div>
  );
};

export default BackToTopButton;
