import { LegacyHeaderVariant } from '@uikit/components/LegacyHeader/consts';

export const getEnercityFooterVariant = (
  headerVariant: LegacyHeaderVariant
) => {
  switch (headerVariant) {
    case LegacyHeaderVariant.BusinessAccounts:
    case LegacyHeaderVariant.Gewerbekunden:
    case LegacyHeaderVariant.GWK:
    case LegacyHeaderVariant.KeyAccounts:
    case LegacyHeaderVariant.Konzern:
      return 'gwk';
    default:
      return 'default';
  }
};
