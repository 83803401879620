import React from 'react';
import PageHead from '../PageHead/PageHead';
import type { PageProps } from './interfaces';

const Page = ({ children, meta, pageRef }: PageProps): JSX.Element => {
  return (
    <>
      <PageHead {...meta} />
      <main ref={pageRef}>{children}</main>
    </>
  );
};

export default Page;
