import ContentsToReact from '@/components/ContentsToReact/ContentsToReact';
import GenericModals from '@/components/GenericModals/GenericModals';
import Page from '@/components/Page/Page';
import ZendeskScript from '@/components/ZendeskScript/ZendeskScript';
import { extractContents } from '@/helpers/extractContents';
import extractPageMetaFromRouteData from '@/helpers/extractPageMetaFromRouteData';
import { getEnercityFooterVariant } from '@/helpers/getEnercityFooterVariant';
import { useInitialPageDataLayer } from '@/hooks/useInitialPageDataLayer/useInitialPageDataLayer';
import { PageModel } from '@/models/page';
import BackToTopButton from '@uikit/components/BackToTopButton/BackToTopButton';
import BodyBackground from '@uikit/components/BodyBackground/BodyBackground';
import Layout from '@uikit/components/Layout/Layout';
import LegacyHeader from '@uikit/components/LegacyHeader/LegacyHeader.wrapper';
import { LegacyHeaderVariant } from '@uikit/components/LegacyHeader/consts';
import PromoBanner from '@uikit/components/PromoBanner/PromoBanner';
import usePromoBanner from '@uikit/components/PromoBanner/usePromoBanner';
import { CmsComponentsContext } from '../CmsComponentsContext';
import cmsComponents from './DefaultPage.cmsComponents';
import type { DefaultPageProps } from './interfaces';

const DefaultPage = (pageData: PageModel<DefaultPageProps>): JSX.Element => {
  const meta = extractPageMetaFromRouteData(pageData);

  const { isBannerHidden } = usePromoBanner(pageData.props.promoBanner);

  const {
    extractedContents: [headerArea, stageArea, footerContents, noticeBarArea],
    restContents,
  } = extractContents(
    ['header', 'stage-area', 'footer', 'notice-bar-area'],
    pageData.contents
  );

  const footerVariant = getEnercityFooterVariant(
    pageData.props.headerLegacyVariant ?? LegacyHeaderVariant.Privatkunde
  );

  if (footerContents && footerContents.length > 0) {
    footerContents[0].props = {
      ...footerContents[0].props,
      variant: footerVariant,
    };
  }

  const [header] = headerArea;

  const hasFullscreenStage =
    stageArea?.[0]?.contents?.[0]?.componentName === 'fullscreen-stage';

  useInitialPageDataLayer();

  return (
    <CmsComponentsContext.Provider value={cmsComponents}>
      <BodyBackground variant={pageData.props.bodyBackgroundVariant} />

      {noticeBarArea && <ContentsToReact contents={noticeBarArea} />}

      {hasFullscreenStage && (
        <>
          <LegacyHeader
            {...header.props}
            contents={header.contents}
            isFullWidth
          />
          {stageArea && <ContentsToReact contents={stageArea} />}
        </>
      )}

      <Layout variant={'default'} background={'white'}>
        <Page meta={meta}>
          {!hasFullscreenStage && (
            <>
              <ContentsToReact contents={headerArea} />
              <ContentsToReact contents={stageArea} />
            </>
          )}
          {restContents && <ContentsToReact contents={restContents} />}
        </Page>

        <BackToTopButton
          isFixed={
            pageData.props.promoBannerVisible === 'promoBanner' &&
            !isBannerHidden
          }
        />

        {footerContents && <ContentsToReact contents={footerContents} />}
      </Layout>

      <GenericModals modals={pageData.modals} />

      {pageData.props.promoBannerVisible === 'promoBanner' &&
        pageData.props.promoBanner && (
          <PromoBanner banner={pageData.props.promoBanner} />
        )}
      <ZendeskScript loadZendesk={pageData.props.showZendesk} />
    </CmsComponentsContext.Provider>
  );
};

export default DefaultPage;
