import clsx from 'clsx';
import { MouseEvent, useEffect, useState } from 'react';
import Portal from '../../../components/Portal/Portal';
import delay from '../../../helpers/delay';
import useIsMobile from '../../../hooks/useIsMobile/useIsMobile';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Button from '../Button/Button';
import { ButtonAlign, ButtonColor, ButtonSize } from '../Button/consts';
import Icon from '../Icon/Icon';
import Lightbox from '../Lightbox/Lightbox';
import type { PromoBannerProps } from './interfaces';
import styles from './PromoBanner.module.scss';
import usePromoBanner from './usePromoBanner';

const PromoBanner = ({ banner }: PromoBannerProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { hideBanner, isBannerHidden } = usePromoBanner(banner);

  const isMobile = useIsMobile();

  const clickableProps = extractClickableProps(banner);
  useEffect(() => {
    (async () => {
      // on initial render, show banner after 2 seconds
      await delay(2000);
      setIsVisible(!isBannerHidden);
    })();
  }, [isBannerHidden]);

  const togglePromoBanner = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClose = ($event: MouseEvent<HTMLElement>) => {
    $event.stopPropagation();
    setIsVisible(false);
    hideBanner();
  };

  const content = (
    <div className={styles.content}>
      <Icon
        size="iconSize24"
        variant={'action/close'}
        className={styles.lightboxClose}
        onClick={togglePromoBanner}
        isOutlined
      />
      <Icon
        size="iconSize64"
        variant={banner.contentIcon}
        className={styles.contentIcon}
        isOutlined
      />
      <span className={styles.contentHeadline}>{banner.headline}</span>
      <span className={styles.contentDescription}>{banner.description}</span>
      <Button
        color={ButtonColor.Primary}
        size={ButtonSize.Medium}
        className={styles.button}
        align={ButtonAlign.Center}
        {...clickableProps}
      >
        {banner.buttonText}
      </Button>

      <div className={styles.hiddenOnMobile}>
        <Icon
          size="iconSize16"
          variant={'action/chevron-down'}
          className={styles.collapseIcon}
          onClick={togglePromoBanner}
          isOutlined
        />
      </div>
    </div>
  );

  return (
    <Portal id={`promo-banner-${banner.id}`}>
      <div className={styles.wrapper}>
        <div className={styles.rotatedWrapper}>
          <div
            className={clsx(styles.main, {
              [styles.expanded]: isExpanded,
              [styles.visible]: isVisible && !isExpanded,
              [styles.hidden]: !isVisible,
            })}
          >
            <div className={styles.banner} onClick={togglePromoBanner}>
              <div className={styles.headlineWrapper}>
                <Icon
                  size="iconSize32"
                  variant={banner.icon}
                  className={styles.bannerIcon}
                  isOutlined
                />

                <strong className={styles.bannerHeadline}>
                  {banner.title || banner.name}
                </strong>
              </div>
              <Icon
                size="iconSize24"
                variant={'action/close'}
                className={styles.bannerClose}
                onClick={handleClose}
                isOutlined
              />
            </div>
            <div className={styles.hiddenOnMobile}>
              <hr className={clsx(styles.divider, styles.hiddenOnMobile)} />
              {content}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.lightboxWrapper}>
        <Lightbox
          onClose={togglePromoBanner}
          showCloseIcon={false}
          active={isMobile && isExpanded}
        >
          <div className={styles.mobileContentWrapper}>{content}</div>
        </Lightbox>
      </div>
    </Portal>
  );
};

export default PromoBanner;
