import useModal from '@/hooks/useModal/useModal';
import ModalContent from '@uikit/components/Modal/ModalContent';
import ContentsToReact from '../ContentsToReact/ContentsToReact';
import ModalPortal from '../ModalPortal/ModalPortal';
import { GenericModalProps } from './interfaces';

const GenericModals = ({
  modals,
  modalComponent,
}: GenericModalProps): JSX.Element => {
  const { isVisibleRearLayer, closeModal } = useModal();

  return (
    <>
      {modals?.map(
        (modal) =>
          modal && (
            <ModalPortal
              id={modal.id}
              key={modal.id}
              onClose={closeModal}
              mainLayerVisible={!isVisibleRearLayer}
              modalComponent={modalComponent}
              rearLayer={
                modal.rearLayer && (
                  <ModalContent background="none" width={modal.props.width}>
                    <ContentsToReact contents={modal.rearLayer} />
                  </ModalContent>
                )
              }
            >
              <ModalContent width={modal.props.width}>
                <ContentsToReact contents={modal.contents} />
              </ModalContent>
            </ModalPortal>
          )
      )}
    </>
  );
};

export default GenericModals;
