import { ExtractContentsArgs, ExtractContentsResult } from './types';

export const extractContents = (
  ...[componentNames, contents = []]: ExtractContentsArgs
): ExtractContentsResult => {
  return contents.reduce<ExtractContentsResult>(
    (previousValue, content) => {
      const index = componentNames.indexOf(content.componentName);
      if (index >= 0) {
        if (previousValue.extractedContents[index]) {
          previousValue.extractedContents[index].push(content);
        } else {
          previousValue.extractedContents[index] = [content];
        }
        return previousValue;
      }

      previousValue.restContents.push(content);
      return previousValue;
    },
    {
      extractedContents: [],
      restContents: [],
    }
  );
};
