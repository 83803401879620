import usePathname from '@/hooks/usePathname/usePathname';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import type { ZendeskScriptProps } from './types';

const ZENDESK_SCRIPT_KEY = process.env.ZENDESK_SCRIPT_KEY;

const ZendeskScript = ({ loadZendesk = false }: ZendeskScriptProps) => {
  const pathname = usePathname();
  const lastPathNameRef = useRef('');

  useEffect(() => {
    if (lastPathNameRef.current === pathname) return;

    if (
      typeof window !== 'undefined' &&
      'zE' in window &&
      typeof window.zE === 'function'
    ) {
      window.zE('messenger', loadZendesk ? 'show' : 'hide');
    }

    lastPathNameRef.current = pathname;
  }, [loadZendesk, pathname]);

  if (!loadZendesk) {
    return null;
  }

  return (
    <Script
      data-cmp-vendor="s902"
      type="text/plain"
      className="cmplazyload"
      data-cmp-src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_SCRIPT_KEY}`}
      id="ze-snippet"
      strategy="lazyOnload"
    />
  );
};

export default ZendeskScript;
