import useLocalStorage from '@/hooks/useLocalStorage/useLocalStorage';
import { PromoBanner } from './interfaces';

const usePromoBanner = (banner?: PromoBanner) => {
  const [hiddenBanners, setHiddenBanners] = useLocalStorage<
    { id: string; hiddenUntil: string }[]
  >('enercity@hiddenBanners', []);

  const hideBanner = () => {
    const date = new Date();
    date.setHours(date.getHours() + 12);
    if (banner) {
      setHiddenBanners((oldHiddenBanners) => [
        ...oldHiddenBanners,
        { id: banner.id, hiddenUntil: date.toISOString() },
      ]);
    }
  };

  const checkIfBannerIsHidden = () => {
    if (banner) {
      if (
        !!banner.expiryDate &&
        new Date(banner.expiryDate).getTime() < Date.now()
      ) {
        return true;
      }

      const hiddenBanner = hiddenBanners.find(
        (hiddenBanner) => hiddenBanner.id === banner.id
      );

      if (hiddenBanner) {
        const hiddenUntil = new Date(hiddenBanner.hiddenUntil).getTime();
        const now = new Date().getTime();

        if (now < hiddenUntil) {
          return true;
        }

        setHiddenBanners((oldHiddenBanners) =>
          oldHiddenBanners.filter(
            (hiddenBanner) => hiddenBanner.id !== banner.id
          )
        );
      }
    }

    return false;
  };

  const isBannerHidden = checkIfBannerIsHidden();

  return { isBannerHidden, hideBanner };
};

export default usePromoBanner;
