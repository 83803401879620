import useBodyClass from '@/hooks/useBodyClass/useBodyClass';
import styles from './BodyBackground.module.scss';
import type { BodyBackgroundProps } from './types';

const BodyBackground = ({ variant }: BodyBackgroundProps) => {
  const bodyClass = styles[variant || 'gray'];

  useBodyClass(bodyClass);

  return null;
};

export default BodyBackground;
