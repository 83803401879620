import ItemToComponent from '../ItemToComponent/ItemToComponent';
import type { ContentsToReactProps } from './interfaces';

const ContentsToReact = ({ contents }: ContentsToReactProps): JSX.Element => {
  return (
    <>
      {contents && contents?.length > 0
        ? contents.map((item) => <ItemToComponent key={item.id} item={item} />)
        : null}
    </>
  );
};

export default ContentsToReact;
